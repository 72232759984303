<template lang="pug">
modal(
  v-model="valueComputed"
  :primary-button="{ text: 'Naar winkelmand' }"
  :secondary-button="{ text: 'Verder winkelen', class:'cart-popup--button'}"
  @submit="navigate"
)
  template(v-slot:title) Bestelling aanpassen?
  template(v-slot:body)
    | Let op! Je hebt al een bestelling geplaatst voor de levering van {{ shippingDate }}.
    | Wil je deze aanpassen, vergeet dan niet om de hele bestelling opnieuw te bevestigen
    | door in je winkelmandje op bevestigen te klikken.

    checkbox.cart-popup--checkbox(
      v-model="hide"
      label="dit bericht niet meer tonen"
      @input="toggleHide"
    )
</template>

<script>
export default {
  name: 'CartPopup',
  components: {
    modal: () => import('@/components/modal'),
    checkbox: () => import('@/components/form/checkbox'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide: false,
    };
  },
  computed: {
    shippingDate() {
      const date = this.$auth.user?.current_order?.data?.shipping_date;

      return date ? this.$moment(date).format('dddd DD MMMM') : null;
    },
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    navigate() {
      this.$router.push('/winkelmandje');
      this.$emit('input', false);
    },
    toggleHide(value) {
      if (value) {
        localStorage.setItem('cartPopup', 'true');
      } else {
        localStorage.setItem('cartPopup', '');
      }
    },
    toggleFilter() {

    },
  },
};
</script>
<style lang="scss">
.cart-popup {

  &--button {
    margin-right: 5px;
  }

  &--checkbox {
    margin-top: 10px;
  }
}
</style>
